body {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
}

.transition-1 {
  z-index: 100;
  background-color: var(--transitionBack);
  pointer-events: none;
  transition: all .5s;
  position: fixed;
}

.transition-1.is_active {
  opacity: 0;
}

.transition-2 {
  z-index: 100;
  background-color: var(--transitionBack);
  width: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(-100%);
}

.transition-2.is_active {
  transform: translateX(0%);
}

.transition-3 {
  z-index: 100;
  background-color: var(--transitionBack);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .5s;
  display: flex;
  position: fixed;
  overflow: hidden;
  transform: translateY(-100%);
}

.transition-3.is_active {
  transform: translateY(0%);
}

#loader {
  z-index: 100;
  scale: 60%;
}

#loader svg {
  stroke-width: 5px;
  stroke: var(--accentColor);
  fill: #fff;
  stroke-dasharray: 500;
  stroke-dashoffset: 1000px;
  animation: 2s linear infinite dash, 2s ease-in infinite filling;
  overflow: visible;
}

:-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=index.c050a371.css.map */
