body {
  text-align: center;
  user-select: none;
}

.transition-1 {
  position: fixed;
  z-index: 100;
  background-color: var(--transitionBack);
  pointer-events: none;
  transition: 0.5s ease;
}

.transition-1.is_active {
  opacity: 0;
}

.transition-2 {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(-100%);
  width: 100%;
  bottom: 0;
  z-index: 100;
  background-color: var(--transitionBack);
  transition: 0.5s ease;
}

.transition-2.is_active {
  transform: translateX(0%);
}

.transition-3 {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  transform: translateY(-100%);
  background-color: var(--transitionBack);
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.transition-3.is_active {
  transform: translateY(0%);
}

#loader {
  /* position: absolute; */
  /* left: 50%;
  top: 50vh; */
  /* translate: -50% -50%; */
  z-index: 100;
  /* animation: spin;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: 0s; */

  scale: 60%;
  /* filter: invert(var(--invertLogo)); */
}

#loader svg {
  overflow: visible;
  stroke-width: 5px;
  stroke: var(--accentColor);
  fill: white;
  stroke-dasharray: 500;
  stroke-dashoffset: 1000;
  animation: dash 2s linear infinite, filling 2s ease-in infinite;
}

:-webkit-scrollbar {
  display: none;
}
